<template>
  <div>
    <div class="list-group">
      <a
        class="list-group-item list-group-item-action"
        download="Informed Consent for Therapy Services"
        href="./assets/forms/Informed Consent for Therapy Services.pdf"
        target="_blank"
        >Informed Consent for Therapy Services</a
      >
      <a
        class="list-group-item list-group-item-action"
        download="Informed Consent for Teletherapy"
        href="./assets/forms/Informed Consent for Teletherapy.pdf"
        target="_blank"
        >Informed Consent for Teletherapy</a
      >
      <a
        class="list-group-item list-group-item-action"
        download="Client Background Information Printable"
        href="./assets/forms/Client Background Information Printable.pdf"
        target="_blank"
        >Client Background Information Printable</a
      >
      <a
        class="list-group-item list-group-item-action"
        download="Client Background Information Template"
        href="./assets/forms/Client Background Information Template.pdf"
        target="_blank"
        >Client Background Information Template</a
      >
      <a
        class="list-group-item list-group-item-action"
        download="Maryland Privacy Notice"
        href="./assets/forms/Maryland Privacy Notice.pdf"
        target="_blank"
        >Maryland Privacy Notice</a
      >
      <a
        class="list-group-item list-group-item-action"
        download="Receipt of Privacy Notice"
        href="./assets/forms/Receipt of Privacy Notice.pdf"
        target="_blank"
        >Receipt of Privacy Notice</a
      >
      <a
        class="list-group-item list-group-item-action"
        download="No Surprises Act Fact Sheet CMS"
        href="./assets/forms/No Surprises Act Fact Sheet CMS.pdf"
        target="_blank"
        >No Surprises Act Fact Sheet</a
      >
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: 'Forms - Dr. Parkhurst',
      meta: [{ property: 'og:keywords', content: 'Forms' }],
    };
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.list-group-item {
  padding: 20px;
  font-weight: bold;

  font-family: 'Montserrat';
  color: #004f66;
  border: 1px solid rgba(0, 79, 102, 0.125);
}

.list-group-item:hover {
  color: #2c3e50;
  background-color: #adad8554;
}
</style>
