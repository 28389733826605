<template>
  <div class="row">
    <div class="col-sm-6 mb-3">
      <img src="@/assets/images/lake.jpg" style="width: 100%" />
    </div>
    <div class="col-sm-6">
      <p>
        Dr. Parkhurst offers individual psychotherapy with adults (18 years and older) who are experiencing a
        broad range of concerns including anxiety, depression, stress, trauma, and relationship issues. These
        may be due to ongoing concerns or situational factors. Dr. Parkhurst has experience supporting clients
        through transitions and life changes. In addition, she works with individuals struggling with
        attention, organization, and concentration skills.
      </p>
      <p>Services are provided via telehealth until it is safe to resume in person sessions.</p>
      <br />
      <p>
        Fees: $150.00 per 50-minute session. Payment may be made by check or Zelle and is expected at the time
        of service.
      </p>
      <p>
        Insurance: Dr. Parkhurst does not currently accept insurance. She provides a billing statement that
        may be submitted to your insurer for reimbursement. It is recommended that you check with your
        insurance provider regarding their policy for out of network reimbursement prior to the initial
        session.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: 'Services - Dr. Parkhurst',
      meta: [{ property: 'og:keywords', content: 'Services' }],
    };
  },
};
</script>
