<template>
  <div>
    <div class="row h-100">
      <div class="col-sm-6">
        <img src="@/assets/images/office_1.jpg" style="width: 100%" />
        <p style="margin-top: 30px">
          Address:
          <a href="http://maps.google.com/?q=14919 Windmill Terrance, Silver Spring, MD 20905" target="blank"
            >14919 Windmill Terrace, Silver Spring, MD 20905</a
          >
        </p>
        <p>Phone: <a href="tel:240-801-6998" target="blank">240.801.6998</a></p>
        <p>
          Email:
          <a href="mailto:parkhurstphd@gmail.com" target="blank">parkhurstphd@gmail.com</a>
        </p>
      </div>
      <div class="col-sm-6 mb-3">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29453.520990839756!2d-76.98103589546248!3d39.108289396372925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7dabf3655502b%3A0xe973ca8e3cbc2990!2s14919%20Windmill%20Terrace%2C%20Silver%20Spring%2C%20MD%2020905!5e0!3m2!1sen!2sus!4v1628006660265!5m2!1sen!2sus"
          width="100%"
          height="100%"
          style="border: 1; min-height: 300px"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: 'Contact - Dr. Parkhurst',
      meta: [{ property: 'og:keywords', content: 'Contact' }],
    };
  },
};
</script>
//
