<template>
  <div class="row h-100">
    <div class="col-sm-6 mb-3">
      <img src="@/assets/images/profile.jpg" style="width: 100%" />
    </div>
    <div class="col-sm-6">
      <p>
        Dr. Parkhurst’s belief that we each have the capacity to grow and heal is why she practices a
        collaborative approach to psychotherapy. Both client and therapist are invested equally in setting
        goals, meeting challenges, and developing the skills needed to live with a sense of meaning and
        happiness.
      </p>
      <p>
        Dr. Parkhurst’s approach to therapy depends on the needs of the client and types of concerns they
        choose to work on. Dr. Parkhurst takes a holistic, interpersonal approach in her work with clients,
        drawing on cognitive behavior therapeutic (CBT) interventions and motivational interviewing methods
        while also integrating mindfulness and breathing strategies.
      </p>
      <p>
        CBT focuses on the feelings and thoughts that affect how people see and respond to their circumstances
        and relationships. Motivational interviewing emphasizes activating internal resources and motivations
        to set goals and work toward the changes most important to you.
      </p>
      <p>
        Dr. Parkhurst's interest in what motivates people and her desire to help led her to the field of
        psychology. Dr. Parkhurst attended the University of Maryland Baltimore County to obtain her master’s
        degree in Community-Clinical Psychology. She then attended The Catholic University of America where
        she earned a second master’s degree and her Doctorate in Clinical Psychology. She began her career
        working with children, adolescents, and adults with a wide range of needs in private practice and
        schools.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: 'About - Dr. Parkhurst',
      meta: [{ property: 'og:keywords', content: 'About' }],
    };
  },
};
</script>
