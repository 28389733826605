<template>
  <div>
    <p class="question">Q: What can I expect from my first session?</p>
    <p>
      A: Clients often feel anxious or uncertain about the first therapy session. I work to create a safe,
      respectful place for you to talk about anything that you need to discuss. The first several sessions are
      “getting to know you” sessions where I will ask you questions about yourself, such as your current
      symptoms, background, psychiatric history, and what you would like to work on. You may have questions
      you want to ask me, too. As we work together, we will develop a plan for changes you want to make and
      how therapy can support those changes.
    </p>
    <p class="question">Q: How long will therapy take?</p>
    <p>
      A: Therapy sessions are 50 minutes long and generally occur weekly. The length of therapy depends on
      many factors including the issues you are seeking help with. As you make progress, your priorities may
      change. We will regularly discuss how therapy is progressing to make sure your priorities are being
      addressed effectively.
    </p>
    <p class="question">Q: How frequently will we meet?</p>
    <p>
      A: Generally, sessions occur weekly, particularly at the beginning. Presenting concerns, stressors,
      financial considerations, and therapist availability can affect session frequency. Session frequency can
      change over time depending on your needs. Less frequent sessions may make sense as you make progress
      toward your goals. Alternatively, if you notice an increase in stressors or symptoms, we might meet more
      frequently.
    </p>
    <p class="question">Q: Do you accept any insurance plans?</p>
    <p>
      A: I am an out-of-network provider and thus do not bill directly to any insurance plans. Session fees
      are $150.00 and are collected at the time of your appointment. Payment is accepted via check, cash, or
      Zelle.
    </p>
    <p class="question">Q: How can I get my insurance to reimburse the cost of my sessions?</p>
    <p>
      A: I provide my clients with a billing statement that can be submitted to their insurance company for
      out-of-network reimbursement of their sessions. It is recommended that you check with your insurance
      provider regarding their policy for out of network reimbursement prior to the initial session. If you
      have out- of-network benefits you may be able to get part, or all of the cost of your sessions
      reimbursed directly to you.
    </p>
    <p class="question">Q: Do you meet with clients for therapy via video (teletherapy or telehealth)?</p>
    <p>
      A: I offer video therapy to clients located throughout the state of Maryland. I use a secure,
      HIPAA-compliant telehealth platform called Doxy.me. You do not need to download any software; I will
      give you with a link that provides access to this platform. Since not all insurance providers reimburse
      for telehealth, you should clarify your insurance plan benefits if you wish to submit for this service.
    </p>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: 'FAQ - Dr. Parkhurst',
      meta: [{ property: 'og:keywords', content: 'FAQ' }],
    };
  },
};
</script>
<style>
.question {
  font-weight: bold;
}
</style>
