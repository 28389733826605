import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueMeta from 'vue-meta';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@/assets/fonts/Raleway/stylesheet.css';
import '@/assets/fonts/Montserrat/stylesheet.css';
import '@/assets/css/custom.css';

import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

Vue.use(PerfectScrollbar);
Vue.use(VueMeta);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
