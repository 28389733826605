<template>
  <div class="text-center">
    <img src="@/assets/images/fall.jpg" class="homeimg mb-3" />

    <p class="text-center">
      Living with purpose, meaning and happiness calls for self-compassion as you work to heal and grow. Dr.
      Stephanie Parkhurst, licensed psychologist, believes we each have the innate ability for personal
      development that is enhanced when we are in a safe and supportive relationship. She recognizes how hard
      it can be to ask for help so she works to establish a strong, therapeutic relationship where needs,
      experiences and goals can be explored in an emotionally safe setting.
    </p>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: 'Home - Dr. Parkhurst',
      meta: [{ property: 'og:keywords', content: 'Home' }],
    };
  },
};
</script>
